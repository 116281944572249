













































	import { Component, Vue } from 'vue-property-decorator';
	import AppBaseContainer from '@/components/util/AppContainerBase.vue';
	import RowRating from '@/components/agent/RowRating.vue';
	import RowTableClassCateg from '@/components/agent/RowTableClassCateg.vue';
	import RowChartBar from '@/components/agent/RowChartBar.vue';
	import Util from '@/utils/Util';
	import FilterBar from '@/components/main/FilterBar.vue';
	import domtoimage from "dom-to-image";
	import { internet } from '@/utils/Internet';
	import MixinMain from '@/mixing/MixinMain.vue';
	import jsPDF from "jspdf";
	@Component({
		name: 'MainAgent',
		components: {
			AppBaseContainer,
			RowRating,
			RowTableClassCateg,
			RowChartBar,
			FilterBar,
		},
	})
	export default class MainAgnet extends MixinMain {
		public title = '';
		public title2 = this.$t("MainAgent.title2");
		public backToText = this.$t("MainAgent.textBackTo");
		public selected = this.$t("MainAgent.selected");
		public filtro_clasificacion = "";
		public filtros_clasificaciones = [];
		public chart_changed = 0;
		public chartClassification = "";
		
		mounted(): void {
			this.title = this.$route.params.agent;
			const request_tipLlam = internet
				.newRequest()
				.get(
					`vue-xentric/tipLlam?FiltrAgent=${this.$route.params.agent}&${this.getFilterUrl}`
				);
				Util.waitForPromises([
					request_tipLlam,
				])
				.then((responses) => {
					this.filtros_clasificaciones = responses[0].data.tipo_llamada;
					this.filtro_clasificacion = responses[0].data.tipo_llamada[0];
					this.chartClassification = responses[0].data.tipo_llamada[0];
					this.chart_changed += 1;
				})
		}
		
		public downloadWithCSS() {
            domtoimage
            .toPng(document.querySelector('#dashboard_main')!)
            .then(function(dataUrl: any) {
                var img = new Image();
                img.src = dataUrl;
                img.onload = function () {
                    var doc :any =''
					//console.log("img:", img.width,  img.height)
                    if (img.width > img.height) {
                        doc = new jsPDF('l', 'mm', [img.width, img.height])
                    } else {
                        doc = new jsPDF('p', 'mm', [img.width, img.height])
                    }          
                    doc.addImage (img, 'jpeg', 10, 10, img.width * 0.145, img.height * 0.145) // La relación se puede ajustar según sea necesario
                    const date = new Date();
                    const filename =
                        "dashboard - " +
                        date.getFullYear() +
                        ("0" + (date.getMonth() + 1)).slice(-2) +
                        ("0" + date.getDate()).slice(-2) +
                        ("0" + date.getHours()).slice(-2) +
                        ("0" + date.getMinutes()).slice(-2) +
                        ("0" + date.getSeconds()).slice(-2) +
                        ".pdf";
                    doc.save(filename);
                };
            })
            .catch(function(error: any) {
                console.error("oops, something went wrong!", error);
            });
        }
		public back(){
			const arrayOfRoute = this.$route.fullPath.split('/');
			let portfolio = decodeURI(
				arrayOfRoute[arrayOfRoute.length - 2]
			);
			this.$router.push({
				name : 'MainPortfolio',
				params : {
					portfolio,
				}
			});
		}
		public changeclassification(classification:string) {
			//console.log("CAMBIANDO A ",classification);
			let newClassification = classification.replaceAll(' ', '_');
			this.chartClassification = newClassification;
			this.chart_changed += 1;
		}

	}
