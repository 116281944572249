
































import {
	Component,
	Prop,
	Emit,
	Watch,
} from 'vue-property-decorator';
import LineChart from '@/components/main/LineChart.vue';
import AppDialog from '@/components/util/AppDialog.vue';
import { ChartData } from 'chart.js';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import MixinMain from '@/mixing/MixinMain.vue';
import { AxiosResponse } from 'axios';
import { LineDialog as Text } from '@/config/Text.json';
import FilterRowChart from '@/components/main/FilterRowChart.vue';
import { IDateSelected } from '@/model/main/IDateSelected';
import { IEvCat } from '@/model/main/IEvCat';
import { IAudiosCategAgent } from '@/model/agent/IAudiosCategAgent';
@Component({
	name: 'LineDialogCate',
	components: {
		LineChart,
		AppDialog,
		FilterRowChart,
	},
})
export default class LineDialogCate extends MixinMain {
	@Prop({
		type: String,
		required: true,
	})
	readonly title!: string;

	@Prop({
		type: String,
		required: false,
	})
	readonly subtitle!: string;

	@Prop({
		type: Boolean,
		required: true,
	})
	readonly isOpen!: boolean;

	@Prop({
		type: String,
		required: true,
	})
	readonly selectedLabel!: string;

	public dateEnd = '';
	public dateStart = '';

	public data: ChartData = {
		datasets: [],
	};
	public isLoading = false;
	public titleText = this.$t("LineDialog.titleText");
	public agentSelected = '';
	public listenOpen(payload: boolean): void {
		this.onOpen();
	}

	@Emit()
	public onOpen(): boolean {
		return false;
	}

	@Watch('isOpen')
	public chagendIsOpen(newVal: boolean) {
		if (newVal) {
			this.dateEnd = this.getFilter.dateEnd;
			this.dateStart = this.getFilter.dateTo;
			this.getData();
		}
	}

	mounted(): void {
		this.agentSelected = this.$route.params.agent;
		if (this.isOpen) {
			this.dateEnd = this.getFilter.dateEnd;
			this.dateStart = this.getFilter.dateTo;
			this.getData();
		}
	}
	public getData(): void {
		this.isLoading = true;
		const request_evAudios = internet
			.newRequest()
			.get(
				`vue-xentric/evAudiosCategAgente/${this.dateStart}/${this.dateEnd}?${this.getFilterUrl}`
			);
		Util.waitForPromises([request_evAudios])
			.then((response) => {
				const response_1 = response[0] as AxiosResponse;
				if (response_1.data.length > 0) {
					let data: Array<number> = [];
					let labels: Array<string> = [];
					const reduceByCateg: Record<
						string,
						Array<IAudiosCategAgent>
					> = Util.reduceByProperty<
						IAudiosCategAgent,
						'categoria'
					>(response_1.data, 'categoria');
					const categs: Array<IAudiosCategAgent> =
						reduceByCateg[this.selectedLabel];
					const reduceByAgent: Record<
						string,
						Array<IAudiosCategAgent>
					> = Util.reduceByProperty<
						IAudiosCategAgent,
						'agente'
					>(categs, 'agente');
					const finalData: Array<IAudiosCategAgent> =
						reduceByAgent[this.agentSelected];
					for (let item of finalData) {
						data.push(
							parseFloat(
								item.Resultado.toFixed(1)
							)
						);
						labels.push(item.fechaAnalisis);
					}
					let newValues = [];
					let newLabels = [];
					let dic = Util.dateAndValueToDictionary(labels,data);
					
					for (let i of dic){
						newLabels.push(i.date)
						newValues.push(i.value)
					}
					data = newValues;
					labels = newLabels;
					//console.log(labels);
					// this.data.datasets.push({
					// 	data: Util.acumArray(data),
					// });
					this.data = JSON.parse(
						JSON.stringify({
							labels,
							datasets: [
								{
									data: Util.acumArray(
										data
									),
								},
							],
						})
					);
				}
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}

	public listenDateSelected(payload: IDateSelected): void {
		if (
			payload.dateTo !== this.dateStart ||
			payload.dateEnd !== this.dateEnd
		) {
			this.filterByDate(payload);
		}
	}

	private filterByDate(dateFilter: IDateSelected) {
		this.isLoading = true;
		this.dateEnd = dateFilter.dateEnd;
		this.dateStart = dateFilter.dateTo;
		internet
			.newRequest()
			.get(
				//xentric/evAudiosCategCliente
				`vue-xentric/evAudiosCategAgente/${this.dateStart}/${this.dateEnd}?${this.getFilterUrl}`
			)
			.then((response: AxiosResponse) => {
				const response_1 = response as AxiosResponse;
				if (response_1.data.length > 0) {
					const data: Array<number> = [];
					const labels: Array<string> = [];
					const reduceByCateg: Record<
						string,
						Array<IAudiosCategAgent>
					> = Util.reduceByProperty<
						IAudiosCategAgent,
						'categoria'
					>(response_1.data, 'categoria');
					const categs: Array<IAudiosCategAgent> =
						reduceByCateg[this.selectedLabel];
					const reduceByAgent: Record<
						string,
						Array<IAudiosCategAgent>
					> = Util.reduceByProperty<
						IAudiosCategAgent,
						'agente'
					>(categs, 'agente');
					const finalData: Array<IAudiosCategAgent> =
						reduceByAgent[this.agentSelected];
					for (let item of finalData) {
						data.push(
							parseFloat(
								item.Resultado.toFixed(1)
							)
						);
						labels.push(item.fechaAnalisis);
					}

					this.data.datasets.push({
						data,
					});
					this.data = JSON.parse(
						JSON.stringify({
							labels,
							datasets: [
								{
									data,
								},
							],
						})
					);
				} else {
					this.data = JSON.parse(
						JSON.stringify({
							labels: [],
							dataset: [
								{
									data: [],
								},
							],
						})
					);
				}
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}
}
