var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.data,"item-key":"name","loading":_vm.isLoading,"no-data-text":_vm.noDataText,"no-results-text":_vm.noDataFound,"search":_vm.search,"custom-filter":_vm.filterByText,"footer-props":{
		'items-per-page-text': _vm.elementPerPage,
	}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"dense":"","label":_vm.searchText},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.robot",fn:function(ref){
	var item = ref.item;
return [(item.robot === 0)?_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" "+_vm._s(_vm.noCumple)+" ")]):_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(" "+_vm._s(_vm.cumple)+" ")])]}},{key:"item.eval",fn:function(ref){
	var item = ref.item;
return [_c('v-select',{attrs:{"dense":"","color":"","items":_vm.getItemEval(item.robot),"disabled":true,"filled":""},model:{value:(item.eval),callback:function ($$v) {_vm.$set(item, "eval", $$v)},expression:"item.eval"}})]}},{key:"item.tun",fn:function(ref){
	var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"text","dense":"","disabled":true,"outlined":""},model:{value:(item.tun),callback:function ($$v) {_vm.$set(item, "tun", $$v)},expression:"item.tun"}})]}},{key:"item.est",fn:function(ref){
	var item = ref.item;
return [_c('v-select',{attrs:{"color":"","dense":"","items":_vm.estItems,"filled":"","label":""},model:{value:(item.extra),callback:function ($$v) {_vm.$set(item, "extra", $$v)},expression:"item.extra"}})]}},{key:"footer.page-text",fn:function(ref){
		var pageStart = ref.pageStart;
		var pageStop = ref.pageStop;
return [_vm._v(" "+_vm._s(_vm.getTextCountElementPerPage(pageStart, pageStop))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }