
















































import { Component, Watch } from 'vue-property-decorator';
import Rating from '@/components/main/Rating.vue';
import { IRating } from '@/model/util/IRating';
import MixinMain from '@/mixing/MixinMain.vue';
import { MainMenu } from '@/config/Text.json';
import {
	RowRating as Text,
	MainAgent as TextAgent,
} from '@/config/Text.json';
import { IFilterData } from '@/model/main/IFilterData';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { AxiosResponse } from 'axios';
import LineDialogCat from '@/components/agent/LineDialogCat.vue';
import { IAgentCateg } from '@/model/agent/IAgentCateg';
import { IAudiosCategAgent } from '@/model/agent/IAudiosCategAgent';
@Component({
	name: 'RowRating',
	components: {
		Rating,
		LineDialogCat,
	},
})
export default class RowRating extends MixinMain {
	public ratings: Array<IRating> = [];
	public isLoading = true;
	public isOpen = false;
	public labelSelected = '';
	public titleDialog = this.$t("RowRating.titleDialog");
	public sumAllrecord = 0;
	public agentSelected = '';
	@Watch('getFilter')
	public changedGetFilter(newVal: IFilterData) {
		this.getData();
	}
	mounted(): void {
		this.agentSelected = this.$route.params.agent;
		if (this.getFilter !== undefined) {
			this.getData();
		}
	}
	getIconForPosition(index: number): string {
		return MainMenu.icons[index];
	}
	public openModal(name: string, index: number): void {
		if (index < this.ratings.length - 1) {
			this.titleDialog = Util.replaceTextWith(
				this.$t("RowRating.titleDialog") as string,
				'%s',
				name
			);
			this.labelSelected = name;
			this.isOpen = true;
		}
	}
	public listenDialogClose(payload: boolean) {
		this.isOpen = false;
	}
	public getData(): void {
		this.isLoading = true;
		const request_1 = internet
			.newRequest()
			.get(
				`vue-xentric/evAgenteCateg?${this.getFilterUrl}`
			);
		const request_2 = internet
			.newRequest()
			.get(
				`vue-xentric/evAudiosCategAgente?${this.getFilterUrl}`
			);
		Util.waitForPromises([request_1, request_2])
			.then((responses) => {
				// Ratings
				const response_1: AxiosResponse = responses[0] as AxiosResponse;
				const agents: Record<
					string,
					Array<IAgentCateg>
				> = Util.reduceByProperty<
					IAgentCateg,
					'agente'
				>(response_1.data, 'agente');
				const dataSelectedAgents: Array<IAgentCateg> =
					agents[this.agentSelected];
				//console.log(dataSelectedAgents);

				const response_2: AxiosResponse = responses[1] as AxiosResponse;
				const reduceAgents: Record<
					string,
					Array<IAudiosCategAgent>
				> = Util.reduceByProperty<
					IAudiosCategAgent,
					'agente'
				>(response_2.data, 'agente');
				const countElement: number =
					reduceAgents[this.agentSelected].length; //aqui en length es donde me repite la cantidad de audios
				let total = 0;
				const temp_ratings : Array<IRating> = [];
				for (let item of dataSelectedAgents) {
					total += item.Resultado;
					temp_ratings.push({
						name: item.categoria,
						percentage: parseFloat(
							item.Resultado.toFixed(1)
						),
						record: countElement,
						icon: '',
					});
				}
				temp_ratings.push({
					name: TextAgent.extraRating,
					percentage: parseFloat(
						(
							total /
							dataSelectedAgents.length 
						).toFixed(1)
					),
					record: countElement,
					icon: ''
					
				});
				this.ratings = temp_ratings;
				this.sumAllrecord = countElement / dataSelectedAgents.length;
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}
}
