















	import { Component, Prop } from 'vue-property-decorator';
	import MixinMain from '@/mixing/MixinMain.vue';
	import BarChart from '@/components/util/BarChart.vue';
	import { MainPortfolio as Text } from '@/config/Text.json';
	import { ChartData } from 'chart.js';
	import { internet } from '@/utils/Internet';
	import Util from '@/utils/Util';
	import { AxiosResponse } from 'axios';
	import LineDialog from '@/components/portfolio/LineDialog.vue';
	import { ISubCat } from '@/model/portfolio/ISubCat';

	@Component({
		name: 'RowChartSubCategory',
		components: {
			BarChart,
			LineDialog,
		},
	})
	export default class RowChartSubCategory extends MixinMain {
		@Prop({
            type: String,
            required: true,
        })
        readonly classification!: string;
		public title = Util.replaceTextWith(
				this.$t("MainPortfolio.titleChart") as string,
				'%s',
				this.classification.replaceAll('_', ' ').toUpperCase()
			);
		public subTitle = this.$t("MainPortfolio.subTitleChart");
		public chartEvAudiosTip: ChartData = {
			datasets: [],
		};
		public extraData: Array<number> = [];
		public isLoading = false;
		mounted() {
			//this.getAllData();
			this.getAllDataAgent();
		}

		public getAllDataAgent(){
			this.isLoading = true;
			const request1 = internet
				.newRequest()
				.post(
					`getAudioScript?${this.getFilterUrl}`,
					{"agent": this.$route.params.agent}
				);

			Util.waitForPromises([
				request1,
			])
				.then((responses) => {
					this.evaluateEvAudiosTipLlam(
						responses[0] as AxiosResponse
					);
					this.evaluateExtraData(
						responses[0] as AxiosResponse
					);

					this.subTitle = Util.replaceTextWith(
						this.subTitle as string,
						'%s',
						responses[0].data.filter((el:any) => el._id.tipo_llamada === this.classification)[0].count_total
					);
				})
				.catch(console.log)
				.finally(() => {
					this.isLoading = false;
				});
		}

		private evaluateExtraData(response: AxiosResponse) {		
			const arrayOfIEvTip: Array<any> = response.data.filter((el:any) => el._id.tipo_llamada === this.classification);
			for (let evTipLlam of arrayOfIEvTip) {
				this.extraData.push(evTipLlam.count);
				//console.log('y', evTipLlam)
			}
		}
		private evaluateEvAudiosTipLlam(response: AxiosResponse) {
			const subCate: Array<any> = response.data.filter((el:any) => el._id.tipo_llamada === this.classification);

			this.$set(
				this.chartEvAudiosTip,
				'labels',
				subCate.map((ele: any): string => {
					return ele._id.subcategoria;
				})
			);
			this.chartEvAudiosTip.datasets = [
				{	
					data: subCate.map((ele: any): number => {
						return ele.porcentaje;

					}),
				},
			];
		}
	}
