































































import { Component, Watch } from 'vue-property-decorator';
import DataTable from '@/components/util/DataTable.vue';
import { MainPortfolio as Text } from '@/config/Text.json';
import { MainAgent as TextAgent } from '@/config/Text.json';
import MixinMain from '@/mixing/MixinMain.vue';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { ITipLlamCategAgent } from '@/model/agent/ITipLlamCategAgent';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import { AxiosResponse } from 'axios';
import DialogTableClass from '@/components/agent/DialogTableClass.vue';
import DialogAudioAgent from '@/components/agent/DialogAudioAgent.vue';
import { IFilterAudio } from '@/model/agent/IFilterAudio';
import { IFilterEvaluation } from '@/model/evaluation/IFilterEvaluation';
import { IPruebaTipLlam } from '@/model/agent/IPruebaTipLlam';
import DialogResult from '@/components/agent/ResultEvaluation.vue';
import LoginMixin from '@/mixing/MixinLogin.vue';
import { mixins } from 'vue-class-component';
@Component({
	name: 'TableClassificactionCategory',
	components: {
		DataTable,
		DialogTableClass,
		DialogAudioAgent,
		DialogResult,
	},
})
export default class TableClassificactionCategory extends mixins(
	MixinMain,
	LoginMixin
) {
	public title = this.$t("MainPortfolio.titleAgentCat");
	public subTitle = '';
	public isLoading = false;
	public header: Array<IHeaderTable<IDataTable>> = [];
	public rows: Array<IDataTable> = [];
	public selectedAgent = '';
	public isOpenClass = false;
	public selectedCat = '';
	public tasSelectedClass = 0;
	public selectedPorfolio = '';
	public isModal = false;
	// Audio
	public isOpenAudio = false;
	public filterAudio: IFilterAudio = {
		category: '',
		subCategory: '',
		portfolio: '',
		agent: '',
		class: '',
		AudioRuta: '',
	};
	public filterEvaluation: IFilterEvaluation = {
		customer: '',
		campaing: '',
		agent: '',
		callType: '',
		subCategory: '',
		audio: '',
		ruta: '',
	};
	public isEvaluation = false;
	@Watch('getFilter')
	public changedGetFilter(): void {
		this.getData();
	}
	mounted(): void {
		this.selectedAgent = this.$route.params.agent;
		const arrayOfRoute = this.$route.fullPath.split('/');
		this.selectedPorfolio = decodeURI(
			arrayOfRoute[arrayOfRoute.length - 2]
		);
		this.filterAudio.portfolio = this.selectedPorfolio;
		this.filterAudio.agent = this.selectedAgent;
		if (this.getFilter !== undefined) {
			this.getData();
		}
		// console.log("USER: ", this.getUser);
		// console.log("ROLES: ", this.getUser.roles);
		// for (let rol of this.getUser.roles) {
		// 	if (rol === 'superadmin') {
		// 		this.isModal = true;
		// 		break;
		// 	}
		// }
	}

	private getData(): void {
		this.isLoading = true;

		const request_1 = internet
			.newRequest()
			.get(
				`vue-xentric/evTipLlamCategAgente?${this.getFilterUrl}`
			);

		const request_2 = internet
			.newRequest()
			.get(
				`vue-xentric/evCarteraTotalAgenteTipLlam?${this.getFilterUrl}`
			);
		Util.waitForPromises([request_1, request_2])
			.then((responses) => {
				const response_1: AxiosResponse = responses[0] as AxiosResponse;
				const prueba: Array<IPruebaTipLlam> =
					responses[1].data;
				const reduceByAgent: Record<
					string,
					Array<ITipLlamCategAgent>
				> = Util.reduceByProperty<
					ITipLlamCategAgent,
					'agente'
				>(response_1.data, 'agente');
				const pruebaByAgent: Record<
					string,
					Array<IPruebaTipLlam>
				> = Util.reduceByProperty<
					IPruebaTipLlam,
					'agente'
				>(prueba, 'agente');
				const agentByTipLlam : Record<
					string,
					Array<IPruebaTipLlam>
				> = Util.reduceByProperty<
					IPruebaTipLlam,
					'tipo_llamada'
				>(pruebaByAgent[this.filterAudio.agent], 'tipo_llamada');
				const dataAgentSelected: Array<ITipLlamCategAgent> =
					reduceByAgent[this.selectedAgent];
				const reduceByLlam: Record<
					string,
					Array<ITipLlamCategAgent>
				> = Util.reduceByProperty<
					ITipLlamCategAgent,
					'tipo_llamada'
				>(dataAgentSelected, 'tipo_llamada');

				const dataTable: Array<IDataTable> = [];
				for (let key of Object.keys(reduceByLlam)) {
					const row: IDataTable = {};
					row['id'] = Util.replaceTextWith(
						key,
						'_',
						' '
					);
					for (let cat of this.getOrderCat) {
						const find: ITipLlamCategAgent = reduceByLlam[
							key
						].find(
							(e) => e.categoria === cat
						) as ITipLlamCategAgent;
						if (find !== undefined) {
							row[cat] = find.Resultado.toFixed(
								1
							);
						}
					}
					row['total'] = agentByTipLlam[key][0]['Resultado'].toFixed(1);
					dataTable.push(row);
				}
				this.header = Object.keys(dataTable[0]).map(
					(
						key: string
					): IHeaderTable<IDataTable> => {
						let text = key;
						if (text === 'id') {
							text = TextAgent.titleHeader;
						}
						return {
							text,
							value: key,
						};
					}
				);
				this.rows = dataTable;
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}

	public listenClickRow(payload: IDataTable) {
		this.selectedCat = payload.id;
		this.tasSelectedClass = parseFloat(payload.total);
		this.filterAudio.class = Util.replaceTextWith(
			payload.id,
			' ',
			'_'
		);
		this.isOpenClass = true;
	}

	public listenClosedModalClas(): void {
		this.isOpenClass = false;
	}

	public listenClickRowClass(payload: IDataTable): void {
		this.filterAudio.category = payload.cat;
		this.filterAudio.subCategory = payload.sub;
		this.isOpenClass = false;
		this.isOpenAudio = true;
	}

	public listenClickRowAudio(payload: IDataTable) {

		const filter: IFilterEvaluation = {
			customer: this.getFilter.customer as string,
			campaing: this.filterAudio.portfolio,
			agent: this.filterAudio.agent,
			callType: this.filterAudio.class,
			subCategory: this.filterAudio.subCategory,
			audio: payload.id,
			ruta: payload.ruta,
		};
		if (this.isModal) {
			this.filterEvaluation = filter;
		//	this.isOpenAudio = false;
		//	this.isEvaluation = true;
		} else {
			this.$router.push({
				name: 'ResultEvaluation',
				query: {
					filter: JSON.stringify(filter),
				},
				params: {
					prev: 'agent', 
					agent: this.$route.params.agent, 
					portfolio: this.selectedPorfolio,
				},
			});
		}
	}

	public listenCloseDialogAudio(): void {
		this.isOpenAudio = false;
		this.isOpenClass = true;
	}

	public onCloseDialogResult(): void {
		this.isEvaluation = false;
		this.isOpenClass = true;
	}
}
