





























import {
	Component,
	Emit,
	Prop,
	Watch,
} from 'vue-property-decorator';
import MainMixin from '@/mixing/MixinMain.vue';
import AppDialog from '@/components/util/AppDialog.vue';
import Util from '@/utils/Util';
import { internet } from '@/utils/Internet';
import AppDataTable from '@/components/util/DataTable.vue';
import { IFilterAudio } from '@/model/agent/IFilterAudio';
import { IAudioData } from '@/model/agent/IAudioData';
import { IAudioTip } from '@/model/agent/IAudioTip';

import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import DataTable from '@/components/util/DataTable.vue';
@Component({
	name: 'DialogAudioAgent',
	components: {
		AppDialog,
		AppDataTable,
	},
})
export default class DialogAudioAgent extends MainMixin {
	@Prop({
		type: Boolean,
		required: true,
	})
	readonly isOpen!: boolean;

	@Prop({
		type: Object as () => IFilterAudio,
		required: true,
	})
	readonly filterAudio!: IFilterAudio;
	public isLoading = false;
	public header: Array<IHeaderTable<IDataTable>> = [];
	public row: Array<IDataTable> = [];
	public agentSelected = '';
	public data: Array<IFilterAudio> = [];

	mounted(): void {
		if (this.isOpen === true) {
			this.getData();
		}
	}
	@Watch('isOpen')
	public changedIsOpen(): void {
		if (this.isOpen === true) {
			this.getData();
		}
	}

	public get title(): string {
		let title = this.$t("DialogAudioAgent.title") as string;
		title = Util.replaceTextWith(
			title,
			'$1',
			this.filterAudio.agent || ''
		);
		title = Util.replaceTextWith(
			title,
			'$2',
			this.filterAudio.subCategory || ''
		);
		return title;
	}

	private getData(): void {
		this.isLoading = true;
		const request_1 = internet
			.newRequest()
			.get(`xentric/agente/audiosScript?FiltrAgent=${this.filterAudio.agent}&${this.getFilterUrl}`);
		const request_2 = internet
			.newRequest()
			.get(
				`vue-xentric/agente/audiosAgenteResultadoGen?${this.getFilterUrl}`
			);
		Util.waitForPromises([request_1, request_2])
			.then((responses) => {
				const audios: Array<IAudioData> = responses[0]
					.data as Array<IAudioData>;

				const filterAudio: Array<IAudioData> = audios.filter(
					(e: IAudioData) => {
						let find:
							| IAudioData
							| undefined = undefined;
						if (
							// e.campania ===
							// 	this.filterAudio
							// 		.portfolio &&
							e.subcategoria ===
								this.filterAudio
									.subCategory &&
							// e.categoria ===
							// 	this.filterAudio
							// 		.category &&
							// e.agente ===
							// 	this.filterAudio.agent &&
							e.tipo_llamada ===
								this.filterAudio.class
						) {
							find = e;
						}
						return find;
						
					}
				);

				const audiosTips: Array<IAudioTip> = responses[1]
					.data as Array<IAudioTip>;
				const filterAudiosTips: Array<IAudioTip> = [];
				const dataTable: Array<IDataTable> = [];				

				for (let i of filterAudio) {
					const finded: IAudioTip = audiosTips.find(
						(a) => i.audio === a.audio
					) as IAudioTip;
					filterAudiosTips.push(finded);
					const row: IDataTable = {};
					row['id'] = finded.audio;
					row[
						'result'
					] = `${finded.Resultado.toFixed(1)}%`;
					row['meet'] =
						i.encontrado === 1 ? 'Si' : 'No';
					row['ruta'] = finded.AudioRuta;
					dataTable.push(row);
				}
				//return{
				const header: Array<IHeaderTable<
					IDataTable
				>> = [];
				header.push({
					text: this.$t("DialogAudioAgent.audioNombre") as string,
					value: 'id',
					//filterable: true
				});
				header.push({
					text: this.$t("DialogAudioAgent.evaluacion") as string,
					value: 'result',
					//filterable: true,
				});
				header.push({
					text: this.$t("DialogAudioAgent.cumple") as string,
					value: 'meet',
					//filterable: true,
				});
				header.push({
					text: this.$t("DialogAudioAgent.ruta") as string,
					value:'ruta',
					//filterable: false,
				})
				this.header = header;
				this.row = dataTable;
			
			})

			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}

	public listenClickRow(payload: IDataTable) {
		this.onClickRow(payload);
		
	}

	@Emit()
	public onClickRow(payload: IDataTable): IDataTable {
		return payload;
	}
	@Emit()
	public onClosed(): boolean {
		return true;
	}
}
