



































































































































































import {
	Component,
	Emit,
	Prop,
	Watch,
} from 'vue-property-decorator';
import { IFilterEvaluation } from '@/model/evaluation/IFilterEvaluation';
import { IAudioEvaluation } from '@/model/agent/IAudioEvaluation';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import EvaluationDataTable from '@/components/agent/EvaluationDataTable.vue';
import MixinMain from '@/mixing/MixinMain.vue';
import AppDialog from '@/components/util/AppDialog.vue';
import { ISendData } from '@/model/evaluation/ISendData';
import { Icon } from '@/model/util/Icon';
import MixinLogin from '@/mixing/MixinLogin.vue';
import { ResultEvalText as Text } from '@/config/Text.json';
import { mixins } from 'vue-class-component';
@Component({
	name: 'ResultEvaluation',
	components: {
		EvaluationDataTable,
		AppDialog,
	},
})
export default class ResultEvaluation extends mixins(
	MixinMain,
	MixinLogin
) {
	@Prop({
		type: Object as () => IFilterEvaluation,
		required: true,
	})
	readonly filterAudio!: IFilterEvaluation;
	@Prop({
		type: Boolean,
		required: true,
	})
	readonly isOpen!: boolean;

	public isEnabled = false;
	public isLoading = false;
	public rows: Array<IDataTable> = [];
	public header: Array<IHeaderTable<IDataTable>> = [];
	public customer = '';
	public tas = 0;
	public idScript = 0;
	public dateSelected = '';
	public get getTitle(): string {
		return `Evaluación del audio del agente ${this.filterAudio.agent}`;
	}
	mounted(): void {
		if (this.isOpen) {
			this.getData();
		}
	}
	@Watch('isOpen')
	public changedIsOpen(): void {
		if (this.isOpen) {
			this.getData();
		}
		for (let permission of this.getPermission) {
			if (permission.rsname === 'Ejecutar análisis') {
				//console.log(permission.rsname);

				this.isEnabled = true;
			}
		}
	}

	get getDate(): string {
		return new Date().toISOString().slice(0, 10);
	}
	private getData(): void {
		this.isLoading = true;
		const request_1 = internet
			.newRequest()
			.get(`xentric/agente/audiosScriptMod`);

		Util.waitForPromises([request_1])
			.then((responses) => {
				const data: Array<IAudioEvaluation> = responses[0]
					.data as Array<IAudioEvaluation>;
				this.idScript = parseInt(data[0].IDScript);
				const filter: Array<IAudioEvaluation> = data.filter(
					(e) => {
						return (
							e.agente ===
								this.filterAudio.agent &&
							e.campania ===
								this.filterAudio
									.campaing &&
							e.tipo_llamada ===
								this.filterAudio
									.callType &&
							e.audio ===
								this.filterAudio.audio &&
							e.revision_evaluacion ===
								'revision_evaluacion'
						);
					}
				);

				this.customer = this.filterAudio.customer;
				this.dateSelected =
					filter[0].FechaAnalisis || 'Sin datos';

				const active: number = filter.filter(
					(e) => parseInt(e.encontrado) === 1
				).length;
				this.tas = (active * 100) / filter.length;

				const dataTable: Array<IDataTable> = [];
				for (let [index, item] of filter.entries()) {
					//console.log(item);

					const row: IDataTable = {};
					row['cat'] = item.categoria;
					row['sub'] = item.subcategoria;
					row['robot'] = item.encontrado;
					row['eval'] = item.noencontrado;
					row['tun'] = item.tunning;
					//console.log(JSON.stringify(item.tunning))
					row['est'] = '';
					row['extra'] = '';
					row['_id'] = item._id;
					row['id'] = item.id_registro;
					row['mt_id'] = item.IDScript;
					dataTable.push(row);
				}
				const header: Array<IHeaderTable<
					IDataTable
				>> = Object.keys(dataTable[0]).map(
					(
						key: string
					): IHeaderTable<IDataTable> => {
						let text = key;
						switch (key) {
							case 'cat':
								text = this.$t("ResultEvalText.tableCat") as string;
								break;
							case 'sub':
								text = this.$t("ResultEvalText.tableSubcat") as string;
								break;
							case 'robot':
								text = this.$t("ResultEvalText.tableBot") as string;
								break;
							case 'eval':
								text = this.$t("ResultEvalText.tableEval") as string;
								break;
							case 'tun':
								text = this.$t("ResultEvalText.tableTun") as string;
								break;
							case 'est':
								text = this.$t("ResultEvalText.tableTun") as string;
								break;
						}
						return {
							text,
							value: key,
						};
					}
				) as Array<IHeaderTable<IDataTable>>;
				this.rows = dataTable;
				this.header = header;
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}
	public save() {
		this.isLoading = true;
		let error = false;
		///updateEvaluacion/agente/ id_registro
		const requests_1: Array<Record<string, string>> = [];
		const requests_2: Array<Record<string, string>> = [];
		for (let row of this.rows) {
			const found = row['robot'];
			const notFound = row['eval'];
			if (row['extra'] === 'ACEPTADO') {
				if (found == 1 && notFound == 0) {
					requests_1.push({
						encontrado: '0',
						noencontrado: '1',
						_id: row['_id'],
						estado: 'aceptado',
						id: row['id'],
					});
				} else if (found == 0 && notFound == 1) {
					requests_1.push({
						encontrado: '1',
						noencontrado: '0',
						_id: row['_id'],
						estado: 'aceptado',
						id: row['id'],
					});
				} else {
					requests_1.push({
						encontrado: found,
						noencontrado: notFound,
						_id: row['_id'],
						estado: 'aceptado',
						id: row['id'],
					});
				}
				if (
					String(row['tun']).indexOf(
						'INCORPORAR'
					) !== -1
				) {
					requests_2.push({
						sub: row['sub'],
						id: row['mt_id'],
						aux: 'INCORPORAR',
					});
				}
			}
		}
		const pet = [];
		for (let item of requests_1) {
			const re_1 = internet
				.newRequest()
				.put(
					`updateEvaluacion/agente/${item['id']}`,
					{
						encontrado: item['encontrado'],
						noencontrado: item['noencontrado'],
					}
				);
			pet.push(re_1);
			const re_2 = internet
				.newRequest()
				.put(
					`updateModificacion/agente/${item['_id']}`,
					{
						estado: item['estado'],
					}
				);
			pet.push(re_2);
		}
		for (let item of requests_2) {
			const re_1 = internet
				.newRequest()
				.put(
					`updateScript/${item['id']}/${item['sub']}/${item['aux']}`,
					{
						encontrado: item['encontrado'],
						noencontrado: item['noencontrado'],
					}
				);
			pet.push(re_1);
		}
		Util.waitForPromises(pet)
			.then((responses) => {
				if (responses.length === 0) {
					Util.showMessage(
						'No hay nada que guardar',
						Icon.INFO
					);
				}
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}
	public reevaluate(): void {
		this.isLoading = true;
		internet
			.newRequest()
			.get(`revevaudios/${this.idScript}`)
			.then((response) => {
				Util.showMessage(
					'Reevaluación realizada con éxito',
					Icon.SUCCESS
				);
			})
			.catch(() => {
				Util.showMessage(
					'Reevaluación no pudo ser realizada',
					Icon.WARNING
				);
			})
			.finally(() => {
				this.isLoading = false;
			});
	}
	public listenOnClosed() {
		this.onClosed();
	}

	@Emit()
	public onClosed(): boolean {
		return true;
	}
}
